import {
	MobileSheetsBulkUpdate,
	MobileSheetsGeneric,
	MobileSheetsPriceCalculator,
	MobileSheetsPriceDetail,
	RoomTypeDropdown,
} from '#components'
import { Component } from '@vue/runtime-dom'
import { IdMobileSheets } from '../constants/IdMobileSheets'
import { useDateDetailsStore } from '../store/dateDetails'
import { GenericMobileSheetConfiguration } from '../types/GenericMobileSheetConfiguration'
import { TranslationKeys } from '~/i18n/TranslationKeys'

export interface MobileSheetMapEntry {
	mobileSheet: Component
	config: GenericMobileSheetConfiguration
}

export function getMobileSheetById(modal: IdMobileSheets) {
	const modalsMap: Map<IdMobileSheets, MobileSheetMapEntry> = new Map<IdMobileSheets, MobileSheetMapEntry>([
		[
			IdMobileSheets.PriceCalculator,
			{
				mobileSheet: MobileSheetsPriceCalculator,
				config: {
					title: { key: TranslationKeys.CALCULATE_PRICE_MULTIPLE_DATES },
				},
			},
		],
		[
			IdMobileSheets.CalendarBulkUpdate,
			{
				mobileSheet: MobileSheetsBulkUpdate,
				config: {
					title: { key: TranslationKeys.CUSTOMIZE_PRICES_MULTIPLE_DATES },
				},
			},
		],
		[
			IdMobileSheets.CalendarPriceDetails,
			{
				mobileSheet: MobileSheetsPriceDetail,
				config: {
					title: {
						component: RoomTypeDropdown,
						props: {
							onChange: (roomTypeId: number) => {
								const dateDetailsStore = useDateDetailsStore()
								dateDetailsStore.setDetailsRequirements(
									dateDetailsStore.accommodationId,
									roomTypeId,
									dateDetailsStore.date,
									undefined
								)
							},
							compact: true,
							accommodationId: computed(() => useDateDetailsStore().accommodationId),
							selectedId: computed(() => useDateDetailsStore().roomTypeId),
						},
					},
				},
			},
		],
		[
			IdMobileSheets.CalendarAccommodationDetails,
			{
				mobileSheet: MobileSheetsGeneric,
				config: {
					title: { key: TranslationKeys.CUSTOMIZE_PRICES_MULTIPLE_DATES },
				},
			},
		],
	])

	return modalsMap.get(modal)
}
