<script setup lang="ts">
import { PropType, Ref } from 'nuxt/dist/app/compat/vue-demi'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { UserflowId } from '~~/src/constants/UserflowId'
import { BulkUpdateModes } from '~~/src/constants/bulkUpdateModes'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { TrackingMessages } from '~~/src/constants/trackingMessages'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useMobileSheetsStore } from '~~/src/store/mobileSheets'
import { useModifiersStore } from '~~/src/store/modifiers'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { ModifierType, Modifiers } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { Range } from '~~/src/submodules/sharedTypes/common/Range'
import {
	composeMultipleModeTrackingMessage,
	composeSingleModeTrackingMessage,
} from '~~/src/tracking/bulkUpdateTracking'
import { GenericMobileSheetConfiguration } from '~~/src/types/GenericMobileSheetConfiguration'
import { utilDate } from '~~/src/utils/utilDate'
import { utilTracking } from '~~/src/utils/utilTracking'
import { ComponentWithProps } from '../../../types/ComponentWithProp'
import MobileSheetsBulkUpdateStepsSelectedPeriodSummary from '@/components/mobileSheets/bulkUpdate/SelectedPeriodSummary.vue'
import { TestIds } from '~/constants/TestIds'

const props = defineProps({
	config: { type: Object as PropType<GenericMobileSheetConfiguration>, required: true },
})
const { config } = toRefs(props)

// TODO: clean this component
const modifiersStore = useModifiersStore()
const mobileSheetStore = useMobileSheetsStore()
const accommodationStore = useAccommodationsStore()

const modifiersHaveErrors = ref(false)
let trackMessage = {}

const onSingleUpdate = () => {
	modifiersStore.bulkUpdateSingleRoomsModifiers(date.value!, dateFilter.value, currentSingleModeModifiers.value)
	utilTracking.track(TrackingMessages.BULK_PRICE_CUSTOMIZATION, trackMessage)
	mobileSheetStore.closeMobileSheet()
}
const onMultipleUpdate = () => {
	modifiersStore.bulkUpdateMultipleRoomsModifiers(
		date.value!,
		dateFilter.value,
		selectedRoomTypes.value,
		currentModifiers.value.tweak!
	)
	utilTracking.track(TrackingMessages.BULK_PRICE_CUSTOMIZATION, trackMessage)
	mobileSheetStore.closeMobileSheet()
}

const updateTypes = [TranslationKeys.ON_SINGLE_ROOMS, TranslationKeys.ON_MULTIPLE_ROOMS]
const currentEditMode = ref(0)
watch(currentEditMode, () => {
	currentModifiers.value = {}
	currentSingleModeModifiers.value.clear()
	selectedRoomTypes.value = []
})
const updateFunctionToUse = computed(() => (currentEditMode.value == 0 ? onSingleUpdate : onMultipleUpdate))
const onEditModeChange = (index: number) => (currentEditMode.value = index)

const date: Ref<undefined | Range<Date>> = ref()
const onDateChange = (newVal: any) => (date.value = newVal)

// all days of week
const dateFilter = ref([0, 1, 2, 3, 4, 5, 6])
const onDateFilterChange = (days: number[]) => (dateFilter.value = days)

const selectedRoomTypes: Ref<number[]> = ref([])
const calculatedAlready = ref(false)
const buttonDisabled = ref(!calculatedAlready.value && selectedRoomTypes.value.length < 1)
const disableButton = computed(() => modifiersHaveErrors.value || buttonDisabled.value)

const filteredDays = computed((): number[] => utilDate.getMissingDaysOfTheWeek(date.value))
const currentModifiers: Ref<Modifiers> = ref({})
const currentSingleModeModifiers = ref(new Map<number, Modifiers>())
const onModifiersChange = computed(() =>
	currentEditMode.value === 0
		? onSingleModifiersChange
		: (modifiers: Modifiers, ids: number[]) => onMultipleModifiersChange(currentModifiers.value, ids)
)

const onSingleModifiersChange = (modifierMap: Map<number, Modifiers>) => {
	currentSingleModeModifiers.value = modifierMap
	buttonDisabled.value = modifierMap.entries.length > 0 || dateFilter.value.length < 1

	const ids = Array.from(modifierMap.keys())
	const modifiersMap = modifiersStore.getCountActiveModifiers(ids)
	trackMessage = composeSingleModeTrackingMessage(date.value!, dateFilter.value, ids, modifiersMap)

	modifiersStore.setSingleModifiersForModal(date.value!, dateFilter.value, modifierMap, trackMessage)
}

const _onMultipleModifiersChange = (modifiers: Modifiers) =>
	onMultipleModifiersChange(modifiers, selectedRoomTypes.value)
const onMultipleModifiersChange = (modifiers: Modifiers, ids: number[]) => {
	currentModifiers.value = modifiers
	selectedRoomTypes.value = ids
	buttonDisabled.value =
		ids.length < 1 || modifiers.tweak == undefined || (dateFilter.value != undefined && dateFilter.value.length < 1)

	if (buttonDisabled.value == false && !modifiersHaveErrors.value) {
		const modifiersMap = modifiersStore.getCountActiveModifiers(ids)
		trackMessage = composeMultipleModeTrackingMessage(date.value!, dateFilter.value, ids, modifiersMap, modifiers.tweak)
	}
}

const onMultipleModifierRemove = (modifier: ModifierType) => onModifierRemove(selectedRoomTypes.value, modifier)
const onModifierRemove = (ids: number[], modifier: ModifierType) =>
	modifiersStore.bulkRemoveModifier(date.value!, dateFilter.value, ids, modifier)
const onErrorStatusChange = (hasError: boolean) => (modifiersHaveErrors.value = hasError)
const useExternalModifierHandler = computed(() => currentEditMode.value == 1)
const displayExternalModifierHandler = computed(
	() => useExternalModifierHandler.value && selectedRoomTypes.value.length > 0
)
const currency = computed((): Currency => {
	if (selectedRoomTypes.value.length == 0) {
		return {
			code: 'eur',
			locale: 'en',
		}
	}

	const accommodationId = accommodationStore.getRoomTypeById(selectedRoomTypes.value[0]!)!.accommodationId
	return accommodationStore.getAccommodationById(accommodationId)!.currency
})

// Step handling
const { current, goToNext, goToPrevious, isLast, isFirst } = useStepper(['PeriodSelect', 'ModifiersSelect'])
const isNextDisabled = computed<boolean>(() => {
	if (isFirst.value) {
		return date.value == undefined || dateFilter.value.length < 1
	} else {
		return buttonDisabled.value
	}
})
const nextButtonLabel = computed(() => (isLast.value ? TranslationKeys.CONFIRM : TranslationKeys.NEXT))
const previousButtonLabel = computed(() => (isFirst.value ? TranslationKeys.CANCEL : TranslationKeys.PREVIOUS))
const onNext = () => {
	if (isLast.value) {
		updateFunctionToUse.value()
	} else {
		config.value.title = {
			component: MobileSheetsBulkUpdateStepsSelectedPeriodSummary,
			props: {
				selectedDateRange: date.value,
				selectedDays: dateFilter.value,
			},
		} as ComponentWithProps
		goToNext()
	}
}
const onPrevious = () => {
	if (isFirst.value) {
		mobileSheetStore.closeMobileSheet()
	} else {
		config.value.title = { key: TranslationKeys.CUSTOMIZE_PRICES_MULTIPLE_DATES }
		selectedRoomTypes.value = []
		goToPrevious()
	}
}
</script>

<template>
	<MobileSheetsGeneric :config="config">
		<template v-slot:default>
			<div class="flex flex-col gap-6" v-if="current === 'PeriodSelect'">
				<CommonRangeSelector
					:entries="updateTypes"
					:selected-option="currentEditMode"
					@click="onEditModeChange"
					:data-id="UserflowId.BulkUpdateTypeSelector"
				/>
				<DatePeriodSelector
					@change="onDateChange"
					:date="date"
					:start-date="new Date()"
					:data-id="UserflowId.BulkUpdateRangeSelector"
					:test-ids="{
						'date-picker': TestIds.CalendarCustomizePopupPeriodDatepicker,
						field: TestIds.CalendarCustomizePopupPeriodField,
					}"
					:data-testid="TestIds.CalendarCustomizePopupPeriodField"
				/>
				<CommonWeekDaySelector
					:disabled="date == undefined"
					:selected-range="dateFilter"
					:disabled-days="filteredDays"
					@change="onDateFilterChange"
					:data-id="UserflowId.DayFilter"
				/>
			</div>

			<div v-else class="mb-[60px] flex flex-col gap-6 pb-2">
				<ModifiersBulkUpdate
					:date-range="date!"
					:bulk-update-mode="currentEditMode === 0 ? BulkUpdateModes.SINGLE : BulkUpdateModes.MULTIPLE"
					@change="onModifiersChange"
					@cancel="onModifierRemove"
					@error-status-change="onErrorStatusChange"
					:allow-edit="!useExternalModifierHandler"
				/>
			</div>
		</template>
		<template v-slot:footer>
			<div class="flex flex-col bg-white">
				<AnimationCollapse v-if="isLast">
					<ModifiersManager
						v-if="displayExternalModifierHandler"
						@cancel="onMultipleModifierRemove"
						@change="_onMultipleModifiersChange"
						:modifiers-filters="[ModifierType.Tweak]"
						:currency="currency"
						@error-status-change="onErrorStatusChange"
						:removable-modifiers="modifiersStore.getCommonModifiersForRoomTypeId(selectedRoomTypes)"
						:associated-modifiers="[modifiersStore.getAssociatedModifiers(selectedRoomTypes)]"
						class="p-4"
					/>
				</AnimationCollapse>

				<div class="flex justify-start gap-2 border-t border-dark-blue-200 p-4">
					<CommonButton :type="ButtonTypes.SECONDARY" :size="ButtonSizes.TEXT_M" @click="onPrevious">
						<CommonText :text="previousButtonLabel" />
					</CommonButton>
					<CommonButton
						:type="ButtonTypes.PRIMARY"
						:size="ButtonSizes.TEXT_M"
						@click="onNext"
						:disabled="isNextDisabled"
					>
						<CommonText :text="nextButtonLabel" />
					</CommonButton>
				</div>
			</div>
		</template>
	</MobileSheetsGeneric>
</template>
