<script setup lang="ts">
import { utilNumber } from '~~/src/utils/UtilNumber';

const props = defineProps({
    occupiedRoomsLastYear: { type: Number, required: true },
    totalRooms: { type: Number, required: true },
    showComparison: { type: Boolean },
})
const { occupiedRoomsLastYear, totalRooms } = toRefs(props)
const lastYearPercentage = computed(() => (occupiedRoomsLastYear.value / totalRooms.value))

</script>

<template>
    <div class="flex flex-col gap-2 h-full">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
                <span class="text-lg font-semibold text-white">
                    {{ utilNumber.toPercentageWithCap(lastYearPercentage) }}
                </span>
            </div>
            <p class="text-xs text-white"><span class="font-semibold">{{ occupiedRoomsLastYear }}</span> /
                <span>{{ totalRooms }}</span>
            </p>
        </div>
    </div>
</template>