<script setup lang="ts">
import { Range } from '@dev.smartpricing/sp-shared-network-common'
import { TextSizes } from '@/constants/textSizes'
import { utilDate } from '@/utils/utilDate'
import { weekDaysMobile } from '@/constants/weekdays'

const props = defineProps<{
	selectedDateRange: Range<Date>
	selectedDays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[]
}>()
const { selectedDateRange, selectedDays } = toRefs(props)

const isDaySelected = (day: number) => selectedDays.value.includes(day)
</script>

<template>
	<div class="mt-1 flex flex-col gap-2">
		<CommonText
			:text="TranslationKeys.CUSTOMIZE_PRICES_MULTIPLE_DATES"
			:text-size="TextSizes.HEADING_MD"
			class="text-main-blue-600"
		/>

		<CommonText
			:text="utilDate.formatDateRange(selectedDateRange)"
			:text-size="TextSizes.HEADING_MD"
			class="text-dark-blue-800"
		/>

		<ul class="flex items-center justify-start gap-1">
			<li
				v-for="(day, index) in weekDaysMobile"
				class="flex h-5 w-5 items-center justify-center rounded"
				:class="isDaySelected(index) ? 'bg-main-blue-600' : 'bg-dark-blue-100'"
			>
				<CommonText
					:text="day"
					:text-size="TextSizes.PRODUCTIVE_LABEL"
					:class="isDaySelected(index) ? 'text-white' : 'text-dark-blue-400'"
				/>
			</li>
		</ul>
	</div>
</template>
