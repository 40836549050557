<script setup lang="ts">
import { UserflowId } from '~/constants/UserflowId'

const strategiesStore = useStrategiesStore()

const isResettingStrategyChanges = ref(false)
const { isPreviewedStrategyChanged } = storeToRefs(strategiesStore)

const undoButtonIcon = computed(() => {
	if (strategiesStore.isDefaultStrategyChanged && !strategiesStore.isEditableStrategyChanged) {
		return SpCommonIcon.Reload
	}
	return SpCommonIcon.Undo
})

const resetStrategyChangesModalOpen = () => {
	isResettingStrategyChanges.value = true
}

const handleUndoButton = () => {
	if (strategiesStore.isEditableStrategyChanged) {
		strategiesStore.undoStrategyChanges()
	} else if (strategiesStore.isDefaultStrategyChanged) {
		resetStrategyChangesModalOpen()
	}
}

const handleSaveButton = () => {
	strategiesStore.saveStrategyChanges()
}

watch(isPreviewedStrategyChanged, () => {
	if (isPreviewedStrategyChanged.value) {
		strategiesStore.getStrategyPricePredictions()
	}
})
</script>

<template>
	<div class="flex gap-2 items-center justify-end w-full">
		<SpButton
			:size="SpButtonSize.DefaultIconOnly"
			:type="SpButtonType.Ghost"
			:iconName="undoButtonIcon"
			:data-id="UserflowId.StrategyUndoChangesButton"
			:is-disabled="
				(!strategiesStore.isEditableStrategyChanged && !strategiesStore.isDefaultStrategyChanged) ||
				strategiesStore.isDerivedStrategy
			"
			custom-class="fill-white border border-white transition-none disabled:bg-transparent disabled:border-white/30 disabled:fill-white/30"
			@click="handleUndoButton"
		/>
		<SpButton
			:size="SpButtonSize.DefaultIconOnly"
			:type="SpButtonType.Ghost"
			:iconName="SpCommonIcon.Save"
			:data-id="UserflowId.StrategySaveButton"
			:is-disabled="!strategiesStore.isEditableStrategyChanged || strategiesStore.isDerivedStrategy"
			custom-class="fill-white border border-white transition-none disabled:bg-transparent disabled:border-white/30 disabled:fill-white/30"
			@click="handleSaveButton"
		/>
	</div>
	<StrategiesResetStrategyChangesDialog v-model="isResettingStrategyChanges" />
</template>
