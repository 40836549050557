import { Ref } from '@vue/runtime-dom'
import { AnimationTimes } from '../config/AnimationTimes'

export const useWrapperAnimation = (
    animationTime: AnimationTimes,
    mutableElement: Ref<boolean>
) => {
    const animationShowing = ref(false)
    const showWrapper = ref(false)
    const showContents = ref(false)

    watch(mutableElement, (isValid: boolean) => {
        // regardless of the mutation type, the animation is starting
        animationShowing.value = true

        isValid ? onAnimationShow() : onAnimationEnd()
    })

    return { animationShowing, showContents, showWrapper }

    function onAnimationShow() {
        // if we are showing from nothing we need to start by rendering the elements
        showWrapper.value = true

        setTimeout(() => {
            // after 1ms (render time, nextTick would not work)
            // we can queue the contents rendering
            showContents.value = true

            setTimeout(() => {
                // and after the content rendering & animation, we can say the animation is over
                animationShowing.value = false
            }, animationTime)
        }, 1)
    }

    function onAnimationEnd() {
        // if we are hiding
        setTimeout(() => {
            // we need to give time for the rest of the system to react (1ms in case there are animations or renders)
            // then hide the contents first
            showContents.value = false
            setTimeout(() => {
                // wait an additional ms for the content animation to correctly start and render or de-render if necessary
                setTimeout(() => {
                    // and finally hide the wrapper and signal the animation is not showing anymore
                    showWrapper.value = false
                    animationShowing.value = false
                }, animationTime)
            }, 1)
        }, 1)
    }
}
