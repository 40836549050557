<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { CardTypes } from '~~/src/constants/cardTypes'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useCloneObject } from '~~/src/hooks/useCloneObject'
import { useToggle } from '~~/src/hooks/useToggle'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { ModifierType, TweakModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { IndexedPrice, Price } from '~~/src/submodules/sharedTypes/common/Price'
import { IMessageCardData } from '~~/src/types/IMessageCardData'

const props = defineProps({
	price: { type: Object as PropType<Price>, required: true },
	activeModifiers: {
		type: Object as PropType<ModifierType[]>,
		default: [],
	},
	modifiersFilters: {
		type: Object as PropType<ModifierType[]>,
		default: [],
	},
	currency: { type: Object as PropType<Currency>, required: true },
	confirmButtonDataId: { type: String, default: '' },
	linkedRoom: { type: Object as PropType<IndexedPrice> },
	fixedContentHeight: { type: Number },
	expand: { type: Boolean, defult: false },
})
const {
	price,
	activeModifiers,
	currency,
	confirmButtonDataId,
	linkedRoom,
	modifiersFilters,
	fixedContentHeight,
	expand,
} = toRefs(props)
const { clone, cloneDiffers, resetClone } = useCloneObject(price)
const accommodationStore = useAccommodationsStore()
const derivedModifierData = computed(
	() => accommodationStore.getRoomTypeById(price.value.accommodationId)?.linkedRoomType
)
const derivedNotification: IMessageCardData = {
	canClose: false,
	cardType: CardTypes.INFO,
	title: TranslationKeys.DERIVED_ROOM,
	message: TranslationKeys.DERIVED_ROOM_MODIFIER_NOT_SETTABLE,
}
const modifiersHaveErrors = ref(false)
const onErrorStatusChange = (hasError: boolean) => (modifiersHaveErrors.value = hasError)

const emit = defineEmits([EmitsEnum.Click, EmitsEnum.Save, EmitsEnum.Toggle])
const onChange = (newVal: any) => {
	if (clone.customerParams == undefined) {
		clone.customerParams = {
			activeModifiers: [],
			modifiers: newVal,
		}
	} else {
		clone.customerParams.modifiers = newVal
	}
}

const { toggle, toggleValue, setToggle } = useToggle(expand.value)
watch(expand, () => setToggle(expand.value))
const onToggle = () => {
	toggle()
	emit(EmitsEnum.Toggle, toggleValue.value)
}

const onSave = () => {
	setToggle(false)
	emit(EmitsEnum.Save, clone)
	emit(EmitsEnum.Toggle, toggleValue.value)
}
const resetEntry = () => {
	resetClone()
	if (clone.customerParams != undefined) {
		clone.customerParams.modifiers = {}
	}
}
const cloneHasModifiers = computed(() => {
	const modifiers = clone.customerParams?.modifiers || {}
	const count = Object.values(modifiers).filter(
		(el: number | TweakModifierType) => el != 0 && (el as TweakModifierType)?.variationValue != 0
	).length

	return count != 0
})
</script>

<template>
	<div class="flex flex-col" ref="container" style="max-height: inherit">
		<div class="bg-main-blue-200 px-4 py-6" @click="onToggle">
			<div class="flex items-center justify-between">
				<CommonText :text="TranslationKeys.MODIFIERS" />
				<CommonIcon
					:icon-name="SpSvg.BasicCarretUp"
					:icon-size="IconSizes.XS"
					class="mr-[90px] shrink-0 transition-all duration-300"
					:class="toggleValue ? 'rotate-180' : ''"
				/>
			</div>
		</div>
		<AnimationCollapse :duration-ms="500">
			<div v-if="toggleValue" class="flex flex-col overflow-hidden">
				<div
					class="flex flex-col gap-4 overflow-y-auto px-4 py-4"
					:style="fixedContentHeight ? `height: ${fixedContentHeight}px;` : ''"
				>
					<ModifiersEditor
						:modifiers="clone?.customerParams?.modifiers || {}"
						@change="onChange"
						:linked-room="linkedRoom"
						:active-modifiers="activeModifiers"
						:currency="currency"
						@error-status-change="onErrorStatusChange"
						:modifiers-filters="modifiersFilters"
						:class="'gap-4'"
						:is-compact="true"
					/>
				</div>

				<div class="w-screen border-t border-dark-blue-200 py-3">
					<div
						v-if="derivedModifierData == undefined"
						class="flex flex-col gap-6 px-4 py-3"
						:data-id="UserflowId.CollapsablePriceModifiersDetails"
					>
						<div class="flex justify-start gap-2">
							<CommonButton
								:type="ButtonTypes.TERTIARY"
								:size="ButtonSizes.TEXT_M"
								:disabled="!cloneHasModifiers"
								@click="resetEntry"
							>
								<CommonText :text="TranslationKeys.RESET" :text-size="TextSizes.BODY_REGULAR" />
							</CommonButton>
							<CommonButton
								:type="ButtonTypes.PRIMARY"
								:size="ButtonSizes.TEXT_M"
								:data-id="confirmButtonDataId"
								:disabled="!cloneDiffers || modifiersHaveErrors"
								@click="onSave"
							>
								<CommonText :text="TranslationKeys.SAVE" :text-size="TextSizes.BODY_REGULAR" />
							</CommonButton>
						</div>
					</div>
					<div v-if="toggleValue && derivedModifierData != undefined" class="flex flex-col gap-2 px-4 py-3">
						<CommonMessageCard :card-data="derivedNotification" />
						<ModifiersDerivedEditor :derived-room="derivedModifierData" />
					</div>
				</div>
			</div>
		</AnimationCollapse>
	</div>
</template>
