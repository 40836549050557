<script lang="ts" setup>
import { Ref } from 'vue';
import { mobileSheetsConfig } from '~~/src/config/mobileSheets';
import { getMobileSheetById } from '~~/src/config/mobileSheetsMap';
import { useBodyScrollLock } from '~~/src/hooks/useBodyScrollLock';
import { useWrapperAnimation } from '~~/src/hooks/useWrapperAnimation';
import { useMobileSheetsStore } from '~~/src/store/mobileSheets';
import { GenericMobileSheetConfiguration } from '~~/src/types/GenericMobileSheetConfiguration';

const mobileSheetsStore = useMobileSheetsStore()
const displayMobileSheets = computed(() => mobileSheetsStore.mobileSheet != undefined)

// handling animaion details
const { animationTime, paddingFromTop } = mobileSheetsConfig
const { animationShowing, showContents, showWrapper } = useWrapperAnimation(animationTime, displayMobileSheets)

let mappedComponent: Ref<any | undefined> = shallowRef(undefined)
let config: Ref<GenericMobileSheetConfiguration | undefined> = ref(undefined)

// avoiding background scroll
const { setScrollLock } = useBodyScrollLock(displayMobileSheets.value)
watch(displayMobileSheets, (isOpen) => {
    setScrollLock(isOpen)

    if (isOpen) {
        const mappedConfig = getMobileSheetById(mobileSheetsStore.mobileSheet!)!
        config.value = mappedConfig.config
        mappedComponent.value = mappedConfig.mobileSheet
    } else {
        setTimeout(() => {
            config.value = undefined
            mappedComponent.value = undefined
        }, animationTime + 1);
    }
})

const animationStyle = `animation-duration: ${animationTime}ms; transition-duration: ${animationTime}ms; transition-timing-function: ease-in-out;`
const clickStopperClasses = computed(() => {
    const opacity = showContents.value ? 'opacity-0' : 'opacity-100'
    const display = animationShowing.value ? '' : 'hidden'

    return [opacity, display].join(' ')
})

</script>

<template>
    <div v-if="showWrapper">
        <div class="fixed left-0 z-50 w-screen h-screen transition" :class="showContents ? '' : 'opacity-0'"
            :style="animationStyle" style="backdrop-filter: blur(3.5px);" />

        <div class="fixed w-screen h-screen z-50 grid " @click="mobileSheetsStore.closeMobileSheet"
            :style="`grid-template-rows: ${paddingFromTop} calc(100dvh - ${paddingFromTop})`">
            <div />
            <div class="bg-white w-screen h-full grow z-50 rounded-t-2xl relative transition" @click.stop=""
                :class="showContents ? '' : 'translate-y-full'" :style="animationStyle">
                <component :is="mappedComponent" v-bind="{ config }" />
                <div class="absolute w-full h-full rounded-t-2xl transition bg-white top-0" :class="clickStopperClasses"
                    :style="animationStyle" />
            </div>
        </div>
    </div>
</template>