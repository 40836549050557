<script setup lang="ts">
import { PropType, Ref } from 'nuxt/dist/app/compat/vue-demi'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { Range } from '~/submodules/sharedTypes/common/Range'
import { UserflowId } from '~~/src/constants/UserflowId'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { PeriodSelectorMode } from '~~/src/constants/periodSelectorModes'
import { TrackingMessages } from '~~/src/constants/trackingMessages'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useCalculatedQuotationsStore } from '~~/src/store/calculatedQuotation'
import { useMobileSheetsStore } from '~~/src/store/mobileSheets'
import { GenericMobileSheetConfiguration } from '~~/src/types/GenericMobileSheetConfiguration'
import { utilNumber } from '~~/src/utils/UtilNumber'
import { utilTracking } from '~~/src/utils/utilTracking'

const props = defineProps({
	config: { type: Object as PropType<GenericMobileSheetConfiguration>, required: true },
})
const { config } = toRefs(props)

const quotationsStore = useCalculatedQuotationsStore()
const accommodationStore = useAccommodationsStore()
quotationsStore.resetQuotation()

const date: Ref<undefined | Range<Date>> = ref()
const onDateChange = (newVal: any) => {
	date.value = newVal
	calculateButtonDisabled.value = selectedRoomTypes.value.length == 0
}

const selectedRoomTypes: Ref<number[]> = ref([])
const selectedAccommodation = ref()
const calculatedCurrency = ref(accommodationStore.accommodations[0].currency)
const calculatedAlready = ref(false)
const calculateButtonDisabled = ref(!calculatedAlready.value && selectedRoomTypes.value.length < 1)

const onCalculate = () => {
	calculatedAlready.value = true
	calculateButtonDisabled.value = true
	calculatedCurrency.value = accommodationStore.getAccommodationById(selectedAccommodation.value)!.currency

	quotationsStore.requestQuotation(selectedRoomTypes.value, date.value!)
}

const trackCopy = () => {
	utilTracking.track(TrackingMessages.PRICE_COPY, {
		nights: quotationsStore.nightsAmount,
		rooms: quotationsStore.roomAmount,
	})
}

const onRoomTypeChange = (idList: number[], accommodationId: number) => {
	calculatedAlready.value = false
	calculateButtonDisabled.value = idList.length == 0
	selectedRoomTypes.value = idList
	quotationsStore.resetQuotation()
	selectedAccommodation.value = accommodationId
}
</script>

<template>
	<MobileSheetsGeneric :config="config">
		<template v-slot:default>
			<div class="flex flex-col gap-6">
				<DatePeriodSelector
					@change="onDateChange"
					:date="date"
					:start-date="new Date()"
					:data-id="UserflowId.QuotationRangeSelector"
					:selector-mode="PeriodSelectorMode.DAYS"
				/>
				<AccommodationDropdownList :disabled="date == undefined" @change="onRoomTypeChange" />
			</div>
		</template>
		<template v-slot:footer>
			<div class="flex flex-col w-full bg-white">
				<div class="p-4 pb-7">
					<AnimationCollapse>
						<CommonLabel v-if="quotationsStore.calculatedQuotation != undefined" :text="TranslationKeys.CALCULATED_PRICE">
							<CommonClipboardText
								:text="utilNumber.toCurrency(quotationsStore.calculatedQuotation, calculatedCurrency)"
								:on-click-message="TranslationKeys.CLIPBOARD_PRICE_SAVED"
								@click="trackCopy"
								:data-id="UserflowId.PriceCalculationResult"
							/>
						</CommonLabel>
					</AnimationCollapse>
				</div>
				<div class="flex justify-start gap-2 p-4 border-t border-dark-blue-200">
					<CommonButton
						:type="ButtonTypes.SECONDARY"
						:size="ButtonSizes.TEXT_M"
						@click="() => useMobileSheetsStore().closeMobileSheet()"
					>
						<CommonText :text="TranslationKeys.CANCEL" />
					</CommonButton>
					<CommonButton
						:type="ButtonTypes.PRIMARY"
						:size="ButtonSizes.TEXT_M"
						:disabled="calculateButtonDisabled"
						@click="onCalculate"
					>
						<CommonText :text="TranslationKeys.CALCULATE" />
					</CommonButton>
				</div>
			</div>
		</template>
	</MobileSheetsGeneric>
</template>
