<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { TextSizes } from '~~/src/constants/textSizes';
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency';
import { utilNumber } from '~~/src/utils/UtilNumber';

const props = defineProps({
    currentAdr: { type: Number, required: true },
    lastYearAdr: { type: Number, required: true },
    currency: { type: Object as PropType<Currency>, required: true },
    showComparison: { type: Boolean },
    hideBadge: { type: Boolean, default: false }
})
const { currentAdr, lastYearAdr, currency, showComparison } = toRefs(props)
</script>

<template>
    <div class="flex flex-col gap-4">

        <CommonText :text="TranslationKeys.ADR" :text-size="TextSizes.BODY_REGULAR" class="text-dark-blue-800" />

        <div class="flex flex-col gap-2">
            <span class="text-xl font-semibold text-dark-blue-800">
                {{ utilNumber.toCurrency(currentAdr, currency) }}
            </span>
            <ComparisonBadge class="w-fit" v-if="showComparison && !hideBadge" :first-number="lastYearAdr"
                :second-number="currentAdr" />
        </div>
    </div>
</template>