<script setup lang="ts">
import { useOverbooking } from '~/hooks/useOverbooking';
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { TextSizes } from '~~/src/constants/textSizes';
import { utilNumber } from '~~/src/utils/UtilNumber';

const props = defineProps({
    occupiedRooms: { type: Number, required: true },
    occupiedRoomsLastYear: { type: Number, required: true },
    totalRooms: { type: Number, required: true },
    showComparison: { type: Boolean },
    hideBadge: { type: Boolean, default: false }
})
const { occupiedRooms, totalRooms, occupiedRoomsLastYear, showComparison, hideBadge } = toRefs(props)
const currentPercentage = computed(() => (occupiedRooms.value / totalRooms.value))
const lastYearPercentage = computed(() => (occupiedRoomsLastYear.value / totalRooms.value))
const { isOverbooked } = useOverbooking(currentPercentage)
</script>

<template>
    <div class="grid h-full gap-2">
        <div class="grid items-center justify-between gap-4" style="grid-template-columns: 1fr auto">

            <CommonText :text="TranslationKeys.OCCUPANCY" :text-size="TextSizes.BODY_REGULAR" class="col-span-2 text-dark-blue-800"
                style="grid-column: 1;" />

            <span class="col-span-2 text-xl font-semibold" :class="isOverbooked ? 'text-yellow-800' : 'text-dark-blue-800'"
                style="grid-column: 1;">
                {{ utilNumber.toPercentageWithCap(currentPercentage) }}
            </span>

            <div class="flex items-end h-full row-span-2 gap-2 " style="grid-column: 2; grid-row-end: 2">
                <p class="text-xl text-dark-blue-800"><span class="font-semibold"
                        :class="isOverbooked ? 'text-yellow-800' : 'text-dark-blue-800'">{{ occupiedRooms }}</span> /
                    <span>{{ totalRooms }}</span>
                </p>
                <PriceOccupancyMeter :current-occupancy="currentPercentage" />
            </div>
        </div>
        <ComparisonBadge v-if="showComparison && !hideBadge" class="w-fit" :first-number="lastYearPercentage"
            :second-number="currentPercentage" />
    </div>
</template>